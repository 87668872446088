<template>
  <div
    class="prompt-box flex flex-col space-y-2 py-2 bg-white rounded shadow-lg"
    data-cy="prompt-box"
  >
    <div class="flex justify-between px-4">
      <div class="flex flex-col">
        <span class="font-semibold text-gray-600" data-cy="prompt-box-title">
          {{ title }}
        </span>
        <span v-if="description" data-cy="prompt-box-description">
          {{ description }}
        </span>
      </div>
      <div>
        <icon-base
          class="cursor-pointer"
          icon="x"
          data-cy="prompt-box-close"
          @click="!actionsDisabled && $emit('cancel')"
        />
      </div>
    </div>

    <hr />

    <div class="px-3 pt-1">
      <lf-textarea
        :value="localValue"
        :placeholder="placeholder"
        :disabled="actionsDisabled"
        name="prompt-box-value"
        noresize
        show-label
        rows="6"
        @key-released="localValue = $event"
      />
    </div>

    <hr />

    <div class="flex space-x-2 px-4 py-1">
      <color-button
        v-if="showDelete"
        class="w-1/2 bg-blue-200"
        :disabled="actionsDisabled"
        type="error"
        @click="$emit('delete')"
      >
        {{ $t("COMMON.DELETE") }}
      </color-button>
      <outline-button
        v-else
        class="w-1/2"
        :disabled="actionsDisabled"
        @click="$emit('cancel')"
      >
        {{ $t("COMMON.CANCEL") }}
      </outline-button>
      <color-button
        class="w-1/2 bg-blue-200"
        :disabled="actionsDisabled"
        type="unselected"
        @click="$emit('save', localValue)"
      >
        {{ $t("COMMON.SAVE") }}
      </color-button>
    </div>
  </div>
</template>

<script lang="ts">
export const PROMPT_WIDTH = 450;
export const PROMPT_HEIGHT = 315;
const promptWidth = `${PROMPT_WIDTH}px`;
const promptHeight = `${PROMPT_HEIGHT}px`;
</script>

<script setup lang="ts">
import LfTextarea from "@/components/ui/inputs/LfTextarea.vue";
import OutlineButton from "@/components/ui/buttons/OutlineButton.vue";
import ColorButton from "@/components/ui/buttons/ColorButton.vue";
import { computed, ref } from "vue";

interface Props {
  value?: string | null;
  title: string;
  description?: string;
  placeholder?: string;
  actionsDisabled?: boolean;
  deletable?: boolean;
}

const props = withDefaults(defineProps<Props>(), { value: "" });
defineEmits<{
  save: [value: string];
  delete: [];
  cancel: [];
}>();

const localValue = ref(props.value ?? "");

const showDelete = computed(
  () =>
    props.deletable && props.value?.length && props.value === localValue.value
);
</script>

<style scoped>
.prompt-box {
  width: v-bind(promptWidth);
  height: v-bind(promptHeight);
}
</style>
